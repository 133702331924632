import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl'
import { infoLocalizedMessages } from '../account/my_info'
import { Label } from 'spa/components/label'
import {
  LOGIN_MODAL,
  openModal,
  SIGNUP_MODAL,
} from 'spa/action_creators/modals'
import { SHOW_DEFAULT_LOGIN_HEADER_PROMOS } from '../../shared/utils'
import { signup } from 'spa/action_creators/current_user'
import { SpinnerButton } from 'spa/components/button'
import { TextInput } from 'spa/components/text_input'
import { useDispatch, useSelector } from 'react-redux'
import ApplicationModal from 'spa/components/application_modal'
import CampaignLayer from '../campaign_layer/campaign_layer'
import classNames from 'classnames'
import Form from 'spa/components/form'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import styles from './styles'

function NewSignUp({ intl }) {
  const dispatch = useDispatch()
  const activePromo = useSelector(state => state.promos.activePromo)
  const isOpen = useSelector(state => state.modals[SIGNUP_MODAL].enabled)
  const isFromPlayButton = useSelector(
    state => state.modals[SIGNUP_MODAL].data?.isFromPlayButton
  )
  const isSigningUp = useSelector(state => state.isSigningUp)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const onLoginClick = useCallback(() => dispatch(openModal(LOGIN_MODAL)), [
    dispatch,
  ])

  useEffect(() => {
    setFirstName('')
    setLastName('')
    setEmail('')
    setPassword('')
  }, [isOpen, setFirstName, setLastName, setEmail, setPassword])

  const isDisabled =
    !firstName || !lastName || !email || !password || isSigningUp

  const submit = useCallback(
    e => {
      e.preventDefault()
      if (!isDisabled) {
        dispatch(
          signup({
            email,
            password,
            firstName,
            lastName,
          })
        )
      }
    },
    [isDisabled, firstName, lastName, email, password]
  )

  return (
    <ApplicationModal
      className={styles.modal}
      modalKey={SIGNUP_MODAL}
      subtitle={
        (SHOW_DEFAULT_LOGIN_HEADER_PROMOS.includes(activePromo) ||
          !activePromo) &&
        isFromPlayButton ? (
          <>
            <ul className={styles.list}>
              <li className={styles.listItem}>
                <FontAwesomeIcon icon={['fas', 'check-circle']} />
                <FormattedMessage
                  defaultMessage="Listen to song previews"
                  id="djcity.account.signup.text1"
                />
              </li>
              <li className={styles.listItem}>
                <FontAwesomeIcon icon={['fas', 'check-circle']} />
                <FormattedMessage
                  defaultMessage="Start building your crate"
                  id="djcity.account.signup.text2"
                />
              </li>
              <li className={styles.listItem}>
                <FontAwesomeIcon icon={['fas', 'check-circle']} />
                <FormattedMessage
                  defaultMessage="Create your FREE account now"
                  id="djcity.account.signup.create_your_free_account"
                />
              </li>
            </ul>
          </>
        ) : (
          <FormattedMessage
            defaultMessage="Create an account"
            id="djcity.account.signup.createaccount"
          />
        )
      }
      title={
        (SHOW_DEFAULT_LOGIN_HEADER_PROMOS.includes(activePromo) ||
          !activePromo) && (
          <div className={styles.title}>
            {isFromPlayButton ? (
              <FormattedHTMLMessage
                defaultMessage="REGISTER FOR <span>FREE</span> PREVIEW ACCESS"
                id="djcity.common.register_for_free"
              />
            ) : (
              <FormattedHTMLMessage
                defaultMessage="LET'S GET STARTED"
                id="djcity.common.welcome"
              />
            )}
          </div>
        )
      }
    >
      <CampaignLayer isLoginScreen={false}>
        <Form className={styles.signUpForm} onSubmit={submit}>
          <Label
            className={styles.label}
            text={intl.formatMessage(infoLocalizedMessages.email)}
          >
            <TextInput
              className={styles.input}
              onChange={e => setEmail(e.currentTarget.value)}
              value={email}
            />
          </Label>
          <Label
            className={classNames(styles.label, styles.shortLabel)}
            text={intl.formatMessage(infoLocalizedMessages.firstName)}
          >
            <TextInput
              className={classNames(styles.input, styles.shortInput)}
              onChange={e => setFirstName(e.currentTarget.value)}
              value={firstName}
            />
          </Label>
          <Label
            className={classNames(styles.label, styles.shortLabel)}
            text={intl.formatMessage(infoLocalizedMessages.lastName)}
          >
            <TextInput
              className={classNames(styles.input, styles.shortInput)}
              onChange={e => setLastName(e.currentTarget.value)}
              value={lastName}
            />
          </Label>
          <Label
            className={styles.label}
            text={intl.formatMessage(infoLocalizedMessages.password)}
          >
            <TextInput
              className={styles.input}
              onChange={e => setPassword(e.currentTarget.value)}
              type="password"
              value={password}
            />
          </Label>
          <div className={classNames(styles.row, styles.center)}>
            <SpinnerButton
              centered="centered"
              className={styles.spinnerButton}
              disabled={isDisabled}
              loading={isSigningUp}
              type="submit"
            >
              {isFromPlayButton ? (
                <FormattedMessage
                  defaultMessage="Create free account"
                  id="djcity.account.signup.create_account"
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Continue"
                  id="djcity.account.signup.continue"
                />
              )}
            </SpinnerButton>
            <div className={classNames(styles.row, styles.center)}>
              <button
                className={classNames('cleanButton', styles.loginButton)}
                onClick={onLoginClick}
              >
                <FormattedMessage
                  defaultMessage="Already have an account?"
                  id="djcity.account.signup.already_have_an_account"
                />
                &nbsp;
                <span className={styles.loginLink}>
                  <FormattedMessage
                    defaultMessage="Login"
                    id="djcity.account.login.login"
                  />
                </span>
              </button>
            </div>
          </div>
        </Form>
      </CampaignLayer>
    </ApplicationModal>
  )
}

NewSignUp.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
}

export default injectIntl(NewSignUp)
