import { defineMessages, injectIntl } from 'react-intl'
import { Knotch } from 'spa/components/knotch'
import { Link } from 'react-router-dom'
import { Loading } from '../loading'
import { StripeForm } from 'spa/components/stripe_form'
import { Subheading } from '../typography'
import { updateCardInfo as updateCardInfoAction } from 'spa/action_creators/current_user_billing'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import styles from './styles'

const localizedMessages = defineMessages({
  notification: {
    id: 'djcity.billing.payment.card.notification',
    defaultMessage: 'Credit Card',
  },
  update: {
    id: 'djcity.billing.payment.card.update',
    defaultMessage: 'Update Card',
  },
  loading: {
    id: 'djcity.billing.payment.card.loading',
    defaultMessage: 'Loading',
  },
  newcard: {
    id: 'djcity.billing.payment.card.new',
    defaultMessage: 'Enter New Card Info',
  },
  cancel: {
    id: 'djcity.billing.payment.card.cancel',
    defaultMessage: 'Cancel',
  },
})

function CardInput({ intl }) {
  const error = useSelector(state => state.currentUserBilling.error)
  const isFetching = useSelector(state => state.currentUserBilling.isFetching)
  const isProcessing = useSelector(
    state => state.currentUserBilling.isProcessing
  )
  const currentUser = useSelector(state => state.currentUser)

  if (isFetching) {
    return <Loading />
  }

  return (
    <div className={styles.container}>
      {error && (
        <div className={styles.paymentError}>
          <span className={styles.errorText} color="white">
            {error.userDescription
              ? error.userDescription
              : 'You card could not be added.'}
          </span>
        </div>
      )}
      <div>
        <div className={classNames(styles.row, styles.knotches)}>
          <Knotch fat="fat" />
          <Knotch fat="fat" />
          <Knotch className={styles.lastKnotch} fat="fat" />
        </div>
        <div className={styles.paymentSelection}>
          <div className={styles.gatewayInput}>
            <div className={styles.message}>
              <Subheading color="black">
                {intl.formatMessage(localizedMessages.newcard)}
              </Subheading>
            </div>
            <div className={styles.mt20}>
              <StripeForm
                action={updateCardInfoAction}
                currentUser={currentUser}
                disabled={isProcessing}
                submitText={intl.formatMessage(localizedMessages.update)}
              />
            </div>
            <div className={styles.backContainer}>
              <Link
                className={classNames('button', 'secondary', styles.backButton)}
                to="/account/billing"
              >
                {intl.formatMessage(localizedMessages.cancel)}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

CardInput.displayName = 'CardInput'

CardInput.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }).isRequired,
}

const ConnectedCardInput = injectIntl(CardInput)

export { ConnectedCardInput as CardInput }
