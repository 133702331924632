import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import styles from './styles.sass'

import { defineMessages, injectIntl } from 'react-intl'
import { Redirect, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import {
  changeUserSubscription,
  getSubscriptions,
} from 'spa/action_creators/subscriptions'
import {
  CURRENCY_SYMBOL_MAP,
  DEFAULT_SHORT_DATE_OPTION,
} from '../../shared/utils'
import { Knotch } from '../knotch'
import { Loading } from '../loading'
import { localizedMessages } from './localized_messages'
import { Subheading, TextStyle } from '../typography'
import SubscriptionSelection from './subscription_selector'

const SUBSCRIPTIONS = {
  MONTHLY: {
    id: 'djcity.billing.subscription.plans.monthly',
    defaultMessage: 'MONTHLY',
  },
  QUARTERLY: {
    id: 'djcity.billing.subscription.plans.quarterly',
    defaultMessage: 'QUARTERLY',
  },
  BIANNUALLY: {
    id: 'djcity.billing.subscription.plans.biannually',
    defaultMessage: 'BIANNUALLY',
  },
  ANNUALLY: {
    id: 'djcity.billing.subscription.plans.annually',
    defaultMessage: 'ANNUALLY',
  },
}

const PROMOS = defineMessages({
  ALL: {
    id: 'djcity.billing.subscription.plans.promos',
    defaultMessage: 'PROMO',
  },
})

const SubscriptionUpdate = ({ intl }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const {
    currentUser: {
      user: {
        activeSubscription,
        subscription,
        nextCycle,
        paymentProcessorFeatures,
      },
    },
    subscriptions,
  } = useSelector(state => state)
  const { currentPlan, nextPlan } = subscription

  const currentSub = currentPlan.includes('_')
    ? subscriptions.list.find(s => s.subscriptionKey === currentPlan)
    : { name: intl.formatMessage(PROMOS.ALL) }

  const nextSub =
    nextPlan !== 'nextplan'
      ? subscriptions.list.find(s => s.subscriptionKey === nextPlan)
      : currentSub

  const canChangeSubscription = () => {
    return (
      activeSubscription &&
      paymentProcessorFeatures?.changePlan &&
      (nextPlan || currentPlan)
    )
  }
  if (!process.env.IS_TEST && !canChangeSubscription())
    return <Redirect to="/" />

  const [selectedSub, setSelectedSub] = useState(
    nextPlan !== 'nextplan' ? nextPlan : currentPlan
  )

  const updateSubscription = () => {
    if (nextPlan !== selectedSub) {
      dispatch(changeUserSubscription({ subscriptionKey: selectedSub }))
    }
  }

  const handleSubChange = subscriptionKey => {
    setSelectedSub(subscriptionKey)
  }

  useEffect(() => {
    if (!subscriptions.list.length) {
      dispatch(getSubscriptions())
    }
  }, [subscriptions.list])

  if (!currentSub || !nextSub) return <Loading />

  return (
    <div className={styles.container}>
      <div className={styles.message}>
        <Subheading color="black">
          {intl.formatMessage(localizedMessages.changeSubscription)}
        </Subheading>
      </div>
      <div className={classNames(styles.row, styles.knotches)}>
        <Knotch fat="fat" />
        <Knotch fat="fat" />
        <Knotch className={styles.lastKnotch} fat="fat" />
      </div>
      {activeSubscription && (
        <div className={styles.infoRow}>
          <div className={styles.infoBlock}>
            <TextStyle color="grey" padding="paddingTop" variant="label">
              {intl.formatMessage(localizedMessages.subscriptionInfo, {
                currentPlan:
                  currentSub?.name ||
                  intl.formatMessage(SUBSCRIPTIONS[currentPlan.split('_')[0]]),
                currentPlanPrice: currentPlan.price || '',
                currentPlanCurrency:
                  CURRENCY_SYMBOL_MAP[currentPlan.currency || 'USD'],
                nextPlan: intl.formatMessage(
                  nextPlan === 'nextplan'
                    ? SUBSCRIPTIONS[currentPlan.split('_')[0]]
                    : SUBSCRIPTIONS[nextPlan.split('_')[0]]
                ),
                nextPlanStart: intl.formatDate(
                  new Date(nextCycle).toLocaleDateString(),
                  DEFAULT_SHORT_DATE_OPTION
                ),
                nextPlanPrice: nextSub.price || '',
                nextPlanCurrency:
                  CURRENCY_SYMBOL_MAP[nextSub.currency || 'USD'],
              })}
            </TextStyle>
          </div>
        </div>
      )}
      <SubscriptionSelection
        handleSubscriptionChange={handleSubChange}
        subscriptionSelected={selectedSub}
        subscriptions={subscriptions}
      />
      <div className={styles.buttonsContainer}>
        <button
          disabled={nextPlan === selectedSub}
          onClick={updateSubscription}
        >
          {intl.formatMessage(localizedMessages.changePlan)}
        </button>
        <button className="secondary" onClick={() => history.goBack()}>
          {intl.formatMessage(localizedMessages.changeCancel)}
        </button>
      </div>
    </div>
  )
}

SubscriptionUpdate.displayName = 'SubscriptionUpdate'

SubscriptionUpdate.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
    formatDate: PropTypes.func,
  }).isRequired,
}

export default injectIntl(SubscriptionUpdate)
