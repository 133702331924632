import findIndex from 'lodash/findIndex'

export const FEATURE_ENVIRONMENTS = {
  DESKTOP: '292eec61-1eed-4755-8bdf-be2da19df835',
}

export const AVAILABLE_FEATURES = {
  SMASH_VISION_ACCOUNT: 'smash_vision_account',
  CANCEL_SUBSCRIPTION_BUTTON: 'cancel_subscription_button',
  BULK_DOWNLOAD: 'bulk_download',
  RELATED_SONGS_TABBED_STYLE: 'related_songs',
  RELATED_SONGS_STACKED_STYLE: 'related_songs_stacked_style',
}

export const isFeatureEnabled = (state, selectedFeature) => {
  return (
    findIndex(state.features, function(feature) {
      return (
        feature.feature === selectedFeature &&
        feature.appuuid === FEATURE_ENVIRONMENTS.DESKTOP &&
        feature.enabled === true
      )
    }) >= 0
  )
}
