/* eslint-disable no-console */
import * as promosApi from '../api/promos'
import {
  ADD_PROMO_CODE,
  ERROR_RECEIVING_PROMO,
  ERROR_RECEIVING_PROMO_PRICING,
  ERROR_RECEIVING_PROMO_TOKEN,
  ERROR_RECEIVING_PROMO_TOKEN_VALIDATION,
  ERROR_RECEIVING_PROMO_VALIDATION,
  ERROR_RECEIVING_VIP_CODE,
  RECEIVE_PROMO,
  RECEIVE_PROMO_PRICING,
  RECEIVE_PROMO_TOKEN,
  RECEIVE_PROMO_TOKEN_VALIDATION,
  RECEIVE_PROMO_VALIDATION,
  RECEIVE_VIP_CODE,
  REMOVE_PROMO_CODE,
  REQUEST_PROMO,
  REQUEST_PROMO_PRICING,
  REQUEST_PROMO_TOKEN,
  REQUEST_PROMO_TOKEN_VALIDATION,
  REQUEST_PROMO_VALIDATION,
  REQUEST_VIP_CODE,
  RESET_CHECKOUT_ERRORS,
  RESET_PROMO,
  SET_ACTIVE_PROMO,
} from '../action_constants'
import { error, success } from 'react-notification-system-redux'
import { intl } from '../shared/IntlGlobalProvider'
import {
  LONG_AUTO_DISMISS_SECONDS,
  NOTIFICATION_MESSAGES,
  PROMOS,
  SUBSCRIPTIONS_AVAILABLE,
} from '../shared/utils'
import { push } from 'connected-react-router'
import { removeSubscription, selectSubscription } from './subscriptions'

/**
 * PROMOS Utility functions
 */

const notification = notification => {
  return {
    title: notification?.title ? intl.formatMessage(notification.title) : '',
    message: notification?.message
      ? intl.formatMessage(notification.message)
      : '',
    position: 'tr',
    autoDismiss: LONG_AUTO_DISMISS_SECONDS,
  }
}

/**
 * Generic Actions
 */

export function resetErrors() {
  return {
    type: RESET_CHECKOUT_ERRORS,
  }
}
/**
 * PROMOS Actions
 */
export function addPromoCode(code) {
  return {
    type: ADD_PROMO_CODE,
    payload: { code },
  }
}

export function removePromoCode() {
  return {
    type: REMOVE_PROMO_CODE,
  }
}

export function resetPromo(isPromoCode) {
  return {
    type: isPromoCode ? REMOVE_PROMO_CODE : RESET_PROMO,
  }
}

function requestPromo(promo) {
  return {
    type: REQUEST_PROMO,
    payload: { promo },
  }
}

export function requestPromoPricing(promo) {
  return {
    type: REQUEST_PROMO_PRICING,
    payload: { promo },
  }
}

function requestPromoValidation() {
  return {
    type: REQUEST_PROMO_VALIDATION,
  }
}

function requestPromoToken(promoToken) {
  return {
    type: REQUEST_PROMO_TOKEN,
    payload: { promoToken },
  }
}

function requestPromoTokenValidation() {
  return {
    type: REQUEST_PROMO_TOKEN_VALIDATION,
  }
}

function requestVipCode(vipCode) {
  return {
    vipCode,
    type: REQUEST_VIP_CODE,
  }
}

function receivePromo(promoData) {
  return {
    type: RECEIVE_PROMO,
    payload: promoData,
  }
}

function receivePromoPricing(promoData) {
  return {
    type: RECEIVE_PROMO_PRICING,
    payload: promoData,
  }
}

function receivePromoValidation({ promo }) {
  return {
    type: RECEIVE_PROMO_VALIDATION,
    payload: promo,
  }
}

function receivePromoToken({ promoToken }) {
  return {
    type: RECEIVE_PROMO_TOKEN,
    payload: { promoToken },
  }
}

function receivePromoTokenValidation({ promoToken }) {
  return {
    type: RECEIVE_PROMO_TOKEN_VALIDATION,
    payload: promoToken,
  }
}

function receiveVipCode(vipCode) {
  return {
    vipCode,
    type: RECEIVE_VIP_CODE,
  }
}

function errorReceivingPromo({ error }) {
  return {
    type: ERROR_RECEIVING_PROMO,
    payload: error,
  }
}

function errorReceivingPromoPricing({ error }) {
  return {
    type: ERROR_RECEIVING_PROMO_PRICING,
    payload: error,
  }
}

function errorReceivingPromoValidation({ error }) {
  return {
    type: ERROR_RECEIVING_PROMO_VALIDATION,
    payload: error,
  }
}

function errorReceivingPromoToken({ error }) {
  return {
    type: ERROR_RECEIVING_PROMO_TOKEN,
    payload: error,
  }
}

function errorReceivingPromoTokenValidation({ error }) {
  return {
    type: ERROR_RECEIVING_PROMO_TOKEN_VALIDATION,
    payload: error,
  }
}

function errorReceivingVipCode(error) {
  return {
    error,
    type: ERROR_RECEIVING_VIP_CODE,
  }
}

/**
 * Request Promo data by code or name
 * @param {promo: {name: *, code: *, notification: {success: *, error: *}}}
 * @returns {function(*): Promise<{data: *, ok: boolean, status: *, statusCode: *, statusText: *} | undefined>}
 */
export function requestPromos(promo) {
  return function(dispatch, getState) {
    const {
      locale: { locale },
      subscriptions,
    } = getState()
    dispatch(requestPromo())
    dispatch(removeSubscription())
    return promosApi.requestPromo({ ...promo, locale }).then(response => {
      const { data, status } = response
      switch (status) {
        case 200:
          dispatch(receivePromo(data))
          data?.promoName === PROMOS.WINBACK4PLUS2 &&
            selectSubscription(
              subscriptions.list,
              SUBSCRIPTIONS_AVAILABLE.BIANNUALLY,
              dispatch
            )
          data?.messages?.success &&
            dispatch(
              success(
                notification({
                  title: NOTIFICATION_MESSAGES.paymentDetailsSuccessTitle,
                  message: data.messages.success,
                })
              )
            )
          break
        case 204:
          dispatch(resetPromo())
          dispatch(
            errorReceivingPromo({
              error: {
                userDescription: intl.formatMessage({
                  id: 'djcity.common.promo.trial.payment_made.error',
                  defaultMessage:
                    'Sorry, the introductory pricing is available to new users only.',
                }),
              },
            })
          )
          dispatch(
            error(
              notification({
                title: NOTIFICATION_MESSAGES.paymentDetailsErrorTitle,
                message: {
                  id: 'djcity.common.promo.trial.payment_made.error',
                  defaultMessage:
                    'Sorry, the introductory pricing is available to new users only.',
                },
              })
            )
          )
          break
        default:
          dispatch(resetPromo(!!promo.promoCode))
          if (promo.promoName === PROMOS.NETNEW) return
          dispatch(errorReceivingPromo(data))
          data?.error?.userDescription &&
            dispatch(
              error(
                notification({
                  title: NOTIFICATION_MESSAGES.paymentDetailsErrorTitle,
                  message: {
                    id: 'djcity.billing.payment.paymentdetails.fakeerrorid',
                    defaultMessage: data.error.userDescription,
                  },
                })
              )
            )
          break
      }
    })
  }
}

/**
 * Request Promo pricing by code or name
 * @param {promo: {name: *, code: *, notification: {success: *, error: *}}}
 * @returns {function(*): Promise<{data: *, ok: boolean, status: *, statusCode: *, statusText: *} | undefined>}
 */
export function requestPromosPricing(promo) {
  return function(dispatch, getState) {
    const { locale } = getState().locale
    dispatch(requestPromoPricing(promo))
    return promosApi
      .requestPromoPricing({ ...promo, locale })
      .then(response => {
        const { data, status } = response
        switch (status) {
          case 200:
            dispatch(receivePromoPricing(data))
            break
          default:
            dispatch(errorReceivingPromoPricing(data))
            break
        }
      })
  }
}

/**
 * Redeem Promo by code or name (use for discounted/trailing payment)
 * @param {promo: {name: *, code: *, currency: *, notification: {success: *, error: *}}}
 * @returns {function(*): Promise<{data: *, ok: boolean, status: *, statusCode: *, statusText: *} | undefined>}
 */
export function redeemPromos(promo) {
  return function(dispatch) {
    dispatch(requestPromoValidation())
    return promosApi.redeemPromo(promo).then(response => {
      const { data, status } = response
      switch (status) {
        case 200:
          dispatch(receivePromoValidation(data))
          data?.messages?.success &&
            dispatch(
              success(
                notification({
                  title: NOTIFICATION_MESSAGES.paymentDetailsSuccessTitle,
                  message: data.messages.success,
                })
              )
            )
          break
        default:
          dispatch(errorReceivingPromoValidation(data))
          data?.error?.userDescription &&
            dispatch(
              error(
                notification({
                  title: NOTIFICATION_MESSAGES.paymentDetailsErrorTitle,
                  message: {
                    id: 'djcity.billing.payment.paymentdetails.fakeerrorid',
                    defaultMessage: data.error.userDescription,
                  },
                })
              )
            )
          break
      }
    })
  }
}

/**
 * Request Token for use with digital promo asset download (sent via email)
 * @param {message: {success: *, error: *}}
 * @returns {function(*): Promise<{data: *, ok: *, status: *, statusCode: *, statusText: *} | undefined>}
 */
export function requestPromoTokens(promo) {
  return function(dispatch, getState) {
    const activeSubscription = getState()?.currentUser?.user?.activeSubscription
    dispatch(requestPromoToken())
    return promosApi.requestPromoToken(promo.name).then(response => {
      const { data, status } = response
      switch (status) {
        case 200:
          dispatch(receivePromoToken(data))
          promo?.messages?.success &&
            dispatch(
              success(
                notification({
                  title: NOTIFICATION_MESSAGES.paymentDetailsSuccessTitle,
                  message: data.messages.success,
                })
              )
            )
          activeSubscription
            ? dispatch(push('/'))
            : dispatch(push('/payment-details'))
          break
        default:
          dispatch(errorReceivingPromoToken(data))
          data?.messages?.error &&
            dispatch(
              error(
                notification({
                  title: NOTIFICATION_MESSAGES.paymentDetailsErrorTitle,
                  message: data.messages.error,
                })
              )
            )
          break
      }
    })
  }
}

/**
 * Redeem Token for digital promo asset download
 */
export function redeemPromoTokens(promoToken, message) {
  return function(dispatch) {
    if (!promoToken)
      return dispatch(errorReceivingPromoToken({ error: 'No token found' }))
    dispatch(requestPromoTokenValidation())
    return promosApi.redeemPromoToken(promoToken).then(response => {
      const { data, status } = response
      switch (status) {
        case 200:
          dispatch(receivePromoTokenValidation(data))
          break
        default:
          dispatch(errorReceivingPromoTokenValidation(data))
          dispatch(error(notification(message.error[response.status])))
          break
      }
    })
  }
}

/**
 * Redeems VIP code for free access with payment required
 * @param vipCode
 * @returns {function(*): Promise<{data: *, statusText: *, ok: *, status: *, statusCode: *} | undefined>}
 */
export function redeemVipCodes(vipCode) {
  return async function(dispatch) {
    dispatch(requestVipCode(vipCode))
    try {
      const res = await promosApi.redeemVIPCode(vipCode)
      if (res.ok) {
        dispatch(removePromoCode())
        return dispatch(receiveVipCode({ code: vipCode, ...res.data }))
      } else {
        return dispatch(errorReceivingVipCode(res.data.error))
      }
    } catch (e) {
      return dispatch(errorReceivingVipCode(e))
    }
  }
}

export function setActivePromo(promoType) {
  return {
    type: SET_ACTIVE_PROMO,
    payload: promoType,
  }
}

export function setActivePromoThunk(promoType) {
  return async function(dispatch) {
    dispatch(setActivePromo(promoType))
  }
}
