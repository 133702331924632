export const BREAKPOINTS = {
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
  XXL: 1400,
}

export const TRACKLIST = {
  NEW_RELEASES: 'New Releases',
  ARTISTS: 'Artists',
  EXCLUSIVES: 'Exclusives',
  GLOBAL: 'Global',
  HOTBOX: 'Hotbox',
  PLAYLISTS: 'Playlists',
  RECOMMENDED: 'Recommended',
  REMIXERS: 'Remixers',
  GENRES: 'Genres',
  TOP_50: 'Top 50',
  RELATED: 'Track Details - Related',
  RELATED_NEW_TABBED_STYLE: 'Track Details - Recommended (Tabbed Style)',
  RELATED_NEW_STACKED_STYLE: 'Track Details - Recommended (Stacked Style)',
  SEARCH: 'Search',
  HISTORY: 'History',
  UPDATES: 'Updates',
  CRATE: 'Crate',
}
